<template>
	<div class="opts_wrap" v-loading="loading">
		<div class="order_records" v-if="after_sales_info">
			<p>协商记录</p>
			<div class="records_wrap">
				<div class="record_con c">
					<p class="txt">发起了退款申请</p>
					<div class="txt_wrap">
						<el-descriptions labelClassName="la_style" contentClassName="con_style" :colon="false">
							<el-descriptions-item label="操作时间">{{after_sales_info.apply_time}}</el-descriptions-item>
							<el-descriptions-item label="操作人">买家</el-descriptions-item>
							<el-descriptions-item label="售后类型">{{after_sales_info.text_part.aftersale_type_text}}</el-descriptions-item>
							<el-descriptions-item label="货物状态">{{after_sales_info.text_part.logistics_text}}</el-descriptions-item>
							<el-descriptions-item label="退款原因">{{after_sales_info.text_part.reason_text}}</el-descriptions-item>
							<el-descriptions-item label="退款金额">￥{{after_sales_info.refund_amount}}</el-descriptions-item>
							<el-descriptions-item label="退货物流公司" v-if="after_sales_info.aftersale_type==0">{{after_sales_info.return_logistics_company_name}}</el-descriptions-item>
							<el-descriptions-item label="退货物流单号" v-if="after_sales_info.aftersale_type==0">{{after_sales_info.return_logistics_code}}</el-descriptions-item>
							<el-descriptions-item label="退货地址" v-if="after_sales_info.aftersale_type==0">{{after_sales_info.return_address&&after_sales_info.return_address.full_name}}</el-descriptions-item>
						</el-descriptions>
					</div>
				</div>
				<div class="record_con b">
					<p class="txt">{{after_sales_info.text_part.aftersale_status_text}}</p>
					<div class="txt_wrap">
						<el-descriptions labelClassName="la_style" contentClassName="con_style" :column="3" :colon="false">
							<el-descriptions-item label="更新时间">{{after_sales_info.update_time}}</el-descriptions-item>
							<el-descriptions-item label="操作人">供应商</el-descriptions-item>
							<el-descriptions-item label="拒绝原因" v-if="after_sales_info.aftersale_status==27||after_sales_info.aftersale_status==29">{{after_sales_info.text_part.reason_text}}</el-descriptions-item>
							<el-descriptions-item label=""></el-descriptions-item>
						</el-descriptions>
					</div>
				</div>
				<el-divider></el-divider>
				</div>
		</div>
		<div class="btn_wrap">
			<p class="btn" @click="cancel()">关闭</p>
		</div>
	</div>
</template>

<script>
import Vue from 'vue';
import { mapActions,mapState,mapMutations } from "vuex";
import config from "../../assets/js/conf/config";
export default {
	name: "orderAddress",
	props: {
		isLoading: {
			type: Boolean,
			default: false
		},
		show: {
			type: Boolean,
			default: false
		},
		info: {
			type: Object,
			default: function(){
				return {}
			}
		},
	},
	data() {
		return {
			uploadAction:'',
			after_sales_info:'',
			loading: false,
			reject_reason_list:[],
			operate_type_list:[],
			need_reject_reason_code:false,
			need_evidence:false,
			rules: {
			}
		};
	},
	created(){
	},
	mounted() {
		this.init()
	},
	
	computed:{
		...mapState({
			
		}),
	},
	watch:{
		show(val) {
			if(val){
				this.init()
			}
		}
	},
	
	methods: {
		...mapActions({
		}),
		init(){
			this.after_sales_info = this.info.order_after_sales
		},
		cancel(){
			this.$emit('close', {})
		},
		complete(){
			this.$emit('complete',{})
		}
	},

}
</script>

<style scoped>
	.opts_wrap{padding-top: 14px;}
	.opts_wrap .opts_line{margin-top: 20px;display: flex;align-items: center;}
	.opts_line .txt{width: 100%;font-size: 16px;display: flex;}
	.opts_line .txt .label{min-width: 80px;display: inline-block;}
	.opts_wrap .btn_wrap{margin: 40px auto 0 auto;display: flex;align-items: center;justify-content: center;}
	.opts_wrap .btn_wrap .btn{width: 80px;height: 34px;border-radius: 4px;display: flex;justify-content: center;align-items: center;cursor: pointer;box-sizing: border-box;font-size: 14px;}
	.opts_wrap .btn_wrap .btn:nth-child(1){background: var(--red);color: #fff;margin-right: 14px;}
	.opts_wrap .btn_wrap .btn:nth-child(2){background: #fff;color: #333;border: 1px solid #ddd;margin-right: 14px;}
	.ml8{margin-left: 8px;}
	.opts_wrap .btn_wrap .btn:nth-child(3){background: #fff;color: #333;border: 1px solid #ddd;}
	.w80{width: 80px;}
	
	
	.order_records{margin-top: 15px;width: 100%;height: auto;box-sizing: border-box;padding: 0 40px 30px;background: #fff;line-height: 1;}
	.records_wrap{margin-top: 25px;}
	.records_wrap .record_con{padding-left: 42px;position: relative;}
	.record_con::before{width: 32px;height: 32px;border-radius: 50%;display: flex;justify-content: center;align-items: center;position: absolute;top: 0;left: 0;z-index: 2;font-size: 12px;font-weight: bold}
	.record_con.c{padding-bottom: 13px;}
	.record_con.c::before{content: '买家';background: #ffecee;color: var(--red);}
	.record_con.b::before{content: '商家';background: #f0ffee;color: #0e9f1f;}
	.record_con.c::after{content: '';width: 2px;height: 68%;background: #eee;position: absolute;left: 15px;top: 40px;}
	.record_con .txt{font-size: 14px;line-height: 32px;}
	.record_con .txt_wrap{margin-top: 12px;width: 100%;font-size: 12px;}
	.record_con .txt_wrap .red{color: var(--red);}
	
	/deep/.el-input--suffix.is-focus .el-input__inner{border-color: var(--red) !important;}
	/*red border color*/
	/deep/.el-textarea__inner:focus,.el-textarea__inner:hover, .el-input__inner:focus ,.el-select .el-input.is-focus .el-input__inner ,.el-select .el-input__inner:focus ,.el-select .el-input__inner:hover,.el-radio__input.is-checked .el-radio__inner ,.el-radio__inner:hover,.el-radio.is-bordered.is-checked{border-color: var(--red) !important;}
	
	/*red color*/
	/deep/.el-select-dropdown__item.selected ,.el-radio__input.is-checked+.el-radio__label{color: var(--red);}
	
	/*red background color*/
	.el-radio__input.is-checked .el-radio__inner{background-color: var(--red);}
	.el-radio{margin-right:10px;}
	.el-descriptions-item__container{align-item:center;}
	
	

</style>
<style>
	.el-select-dropdown__item.selected{color: var(--red) !important;}
	.el-select .el-input__inner:focus{border-color: var(--red) !important;}
	.el-select .el-input.is-focus .el-input__inner{border-color: var(--red) !important;}
	.el-radio__input.is-checked+.el-radio__label{color: var(--red);}
	.el-radio__input.is-checked .el-radio__inner{border-color:soid var(--red);background:var(--red);border:1px solid var(--red);}
	
	.el-descriptions{font-size:12px;}
	.la_style{color: #999;width: 80px !important;}
</style>
