<template>
	<div>
		<el-dialog title="收货地址" @close="cancel()" :visible.sync="dialog.addressDialog" width="20%" center >
			<div class="opts_wrap" v-loading="loading">
				<div class="opts_line">
					<p class="txt">
						<span class="label">收件人：</span>
						<span class="val">{{address.name}}</span>
					</p>
				</div>
				<div class="opts_line">
					<p class="txt">
						<span class="label">手机号：</span>
						<span class="val">{{address.phone}} <i class="iconfont el-icon-document-copy" @click="copyString(address.phone)"></i></span>
					</p>
				</div>
				<div class="opts_line">
					<p class="txt">
						<span class="label">地址：</span>
						<span class="val">{{address.province}}{{address.city}}{{address.town}}{{address.detail}}</span>
					</p>
				</div>
				<div class="btn_wrap">
					<p class="btn" @click="bindCopy()" v-if="address.name">复制</p>
					<p class="btn" @click="cancel()">关闭</p>
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import Vue from 'vue';
import { mapActions,mapState,mapMutations } from "vuex";
export default {
	name: "orderAddress",
	props: {
		isLoading: {
			type: Boolean,
			default: false
		},
		show: {
			type: Boolean,
			default: false
		},
		order_id: {
			type: Number,
			default: 0
		},
	},
	data() {
		return {
			dialog: {
				addressDialog:false,
			},
			address:'',
			loading: false
		};
	},
	
	created(){
	},
	mounted() {
	},
	
	computed:{
		...mapState({
			
		}),
	},
	watch:{
		show(val) {
			if(val){
				this.address = ''
				this.init()
			}
		}
	},
	
	methods: {
		...mapActions({
			getOrderManageAddressInfo:"ucenter/getOrderManageAddressInfo",
		}),
		init(){
			this.loading = true
			var param = {order_id:this.order_id}
			this.getOrderManageAddressInfo({data:param,success:(res)=>{
				if(res.code===200){
					this.address = res.data
					this.dialog.addressDialog = true
				} else {
					this.$message({message: res.msg,type: 'error',})
					this.$emit('close', {})
				}
				this.loading = false
			}})
		},
		copyString(str){
			var input = document.createElement("input"); // 创建input对象
			input.value = str; // 设置复制内容
			document.body.appendChild(input); // 添加临时实例
			input.select(); // 选择实例内容
			document.execCommand("Copy"); // 执行复制
			document.body.removeChild(input); // 删除临时实例
			this.$message({
				message: '复制成功',
				type: 'success'
			});
		},
		bindCopy(){
			var str = ''
			str += '收件人：'+this.address.name+' '
			str += '手机号：'+this.address.phone+' '
			str += '地址：'+this.address.province+this.address.city+this.address.town+this.address.detail
			var input = document.createElement("input"); // 创建input对象
			input.value = str; // 设置复制内容
			document.body.appendChild(input); // 添加临时实例
			input.select(); // 选择实例内容
			document.execCommand("Copy"); // 执行复制
			document.body.removeChild(input); // 删除临时实例
			this.$message({
				message: '复制成功',
				type: 'success'
			});
	
		},
		cancel(){
			this.$emit('close', {})
			this.dialog.addressDialog = false
		}
	},

}
</script>

<style scoped>
	.opts_wrap{padding-top: 14px;}
	.opts_wrap .opts_line{margin-top: 20px;display: flex;align-items: center;}
	.opts_line .txt{width: 100%;font-size: 16px;display: flex;}
	.opts_line .txt .label{min-width: 80px;display: inline-block;}
	.opts_wrap .btn_wrap{margin: 40px auto 0 auto;display: flex;align-items: center;justify-content: center;}
	.opts_wrap .btn_wrap .btn{width: 80px;height: 34px;border-radius: 4px;display: flex;justify-content: center;align-items: center;cursor: pointer;box-sizing: border-box;font-size: 14px;}
	.opts_wrap .btn_wrap .btn:nth-child(1){background: var(--red);color: #fff;margin-right: 14px;}
	.opts_wrap .btn_wrap .btn:nth-child(2){background: #fff;color: #333;border: 1px solid #ddd;margin-right: 14px;}
	.ml8{margin-left: 8px;}
	.opts_wrap .btn_wrap .btn:nth-child(3){background: #fff;color: #333;border: 1px solid #ddd;}
	.w80{width: 80px;}
	.el-icon-document-copy{cursor: pointer;}
	
	.el-input--suffix .el-input__inner{padding: 0 0 0 12px !important;font-size: 16px;}
	/*red border color*/
	.el-input__inner:focus ,.el-select .el-input.is-focus .el-input__inner ,.el-select .el-input__inner:focus ,.el-radio__input.is-checked .el-radio__inner ,.el-radio__inner:hover{border-color: var(--red);}
	
	/*red color*/
	.el-select-dropdown__item.selected ,.el-radio__input.is-checked+.el-radio__label{color: var(--red);}
	
	/*red background color*/
	.el-radio__input.is-checked .el-radio__inner{background-color: var(--red);}
</style>
