<template>
	<div class="footer">
		<div class="center">
			<footer class="foot">
				<div class="foot_top">
					<div class="item">
						<div class="icon_box"><i class="iconfont icon-youzhikehu"></i></div>
						<div class="con">
							<p class="t1">质量保障</p>
							<p class="t2">品质行货 放心选购</p>
						</div>
					</div>
					<div class="item">
						<div class="icon_box"><i class="iconfont icon-jifen"></i></div>
						<div class="con">
							<p class="t1">价格实惠</p>
							<p class="t2">普惠时价 帮你省钱</p>
						</div>
					</div>
					<div class="item">
						<div class="icon_box"><i class="iconfont icon-fenlei"></i></div>
						<div class="con">
							<p class="t1">品类齐全</p>
							<p class="t2">助您轻松购物</p>
						</div>
					</div>
					<div class="item">
						<div class="icon_box"><i class="iconfont icon-naozhong"></i></div>
						<div class="con">
							<p class="t1">准时送达</p>
							<p class="t2">收货时间由你做主</p>
						</div>
					</div>
				</div>
				<div class="foot_bottom" v-if="showBeian">
<!--          <p>地址：浙江省杭州市滨江区长河街道滨兴路1912号12楼1206室</p>-->
          <p>星观（杭州）数据科技有限公司  Copyright © 2024  <a target="_blank" style="color: #666666;" href="https://beian.miit.gov.cn/">浙ICP备2021028918号</a></p>
				</div>
			</footer>
		</div>
	</div>
</template>

<script>
	export default {
		name: "myfoot",
		props: {
			showBeian: {
				type: Boolean,
				default: false
			}
		},
		data() {
			return {
				show: false,
			};
		},
	}
</script>

<style scoped>
.footer{width: 100%;height: auto;background-color: #ffffff;box-shadow: 0 -1px 1px 0 #eeeeee;}
.footer .foot{width: 100%;height: auto;}
.footer .foot_top{width: 100%;height: 138px;display: flex;justify-content: center;align-items: center;}
.footer .foot_top .item{display: flex;align-items: center;margin: 0 48px;}
.footer .foot_top .item .icon_box{width: 70px;height: 70px;box-sizing: border-box;border-radius: 50%;border: 2px solid #fa5757;background: #fff;display: flex;justify-content: center;align-items: center;}
.footer .foot_top .item .icon_box .iconfont{font-size: 34px;color: #fa5757;}
.footer .foot_top .item .con{margin-left: 20px;line-height: 1;}
.footer .foot_top .item .con .t1{font-size: 16px;color: #333333;}
.footer .foot_top .item .con .t2{font-size: 14px;color: #999999;margin-top: 12px;}
.footer .foot_bottom{width: 100%;text-align: center;line-height: 22px;font-size: 12px;color: #666666;padding-top: 20px;border-top: 1px solid #eee;height: 96px;box-sizing: border-box}
</style>